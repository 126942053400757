import { type Campaign } from './types.js';
import { ParsedQuery } from '@/types/parsedQuery.js';
import { stringify } from '@contra/utilities/queryString';

export const searchIncludesUtmParameters = (query: ParsedQuery) => {
  // eslint-disable-next-line regexp/no-unused-capturing-group
  return /(utm_medium|utm_source|utm_campaign|utm_content|utm_term)/u.test(
    stringify(query),
  );
};

export const getUtmParametersAsCampaign = (query: ParsedQuery): Campaign => {
  const urlParameters = new URLSearchParams(stringify(query));

  // We are returning empty strings here to make it compatible with Segment's context
  // https://github.com/segmentio/analytics-next/blob/2490333f3c32b922887fdb043ca3101d4c5e25df/src/core/events/interfaces.ts#L63-L79
  return {
    content: urlParameters.get('utm_content') ?? '',
    medium: urlParameters.get('utm_medium') ?? '',
    name: urlParameters.get('utm_campaign') ?? '',
    source: urlParameters.get('utm_source') ?? '',
    term: urlParameters.get('utm_term') ?? '',
  };
};
