import { LoadingSpinnerIcon } from '@/components/Icons/LoadingSpinnerIcon.js';
import { EASE_OUT_QUAD } from '@/constants/animation.js';
import { keyframes, styled } from '@/stitches/stitches.config.js';
import { pxToRem } from '@/utilities/pxToRem.js';
import { type CSS } from '@stitches/react';
import {
  type ComponentPropsWithRef,
  type ComponentType,
  createElement,
  type ElementType,
  forwardRef,
  type MouseEventHandler,
  type ReactNode,
  type Ref,
} from 'react';
import {
  type PolymorphicForwardRefExoticComponent,
  type PolymorphicPropsWithoutRef,
} from 'react-polymorphic-types';

const BackgroundKeyframes = keyframes({
  '0%': {
    transform: 'translate(-40%, -50%)',
  },
  '25%': {
    transform: 'translate(-50%, -25%)',
  },
  '50%': {
    transform: 'translate(-25%, -25%)',
  },
  '75%': {
    transform: 'translate(-75%, -50%)',
  },
  '100%': {
    transform: 'translate(-40%, -50%)',
  },
});

const GlowKeyframes = keyframes({
  '0%': {
    opacity: 0.7,
    transform: 'scale(1, 1)',
  },
  '50%': {
    opacity: 0.9,
    transform: 'scale(0.9, 0.9)',
  },
  '100%': {
    opacity: 0.7,
    transform: 'scale(1, 1)',
  },
});

const Container = styled('button', {
  alignItems: 'center',
  borderRadius: '$25',
  display: 'flex',
  fontFamily: '$inter',
  fontSize: '$16',
  fontStyle: 'normal',
  fontWeight: '600',
  gap: '$8',
  height: '$48',
  justifyContent: 'center',
  lineHeight: '$28',
  padding: '$24',
  transition: 'background ease-in-out .2s',
  variants: {
    addBoxShadow: {
      true: {
        boxShadow: '$basic20',
      },
    },
    addMinWidth: {
      true: {
        minWidth: '$200',
      },
    },
    variant: {
      dark: {
        color: '$brandBlack',
      },
      gradient: {
        '&:after, &:before': {
          animationDuration: '9s',
          animationIterationCount: 'infinite',
          animationName: GlowKeyframes,
          borderRadius: 'inherit',
          bottom: 0,
          boxShadow: '0 0 $70 0 rgba(255, 255, 255, 0.5)',
          content: ' ',
          left: 0,
          margin: 0,
          position: 'absolute',
          right: 0,
          top: 0,
          zIndex: 1,
        },
        '&:before': {
          animationDelay: '1s',
          animationDuration: '4s',
          boxShadow: '0 0 $8 0 rgba(255, 255, 255, 0.5)',
        },
        '&:hover': {
          transform: 'scale(1.1, 1.1)',
        },
        backgroundColor: 'transparent',
        color: '$brandBlack',
        position: 'relative',
        transition: `transform ${EASE_OUT_QUAD} 0.2s`,
        zIndex: 1,
      },
      primary: {
        '&:disabled': {
          backgroundColor: '$gray20',
          borderColor: 'transparent',
          color: '$gray40',
        },
        '&:focus': {
          backgroundColor: '$gray80',
        },
        '&:hover:enabled': {
          backgroundColor: '$gray80',
        },
        backgroundColor: '$gray90',
        borderColor: 'transparent',
        color: '$white',
      },
      primarySmall: {
        '&:disabled': {
          backgroundColor: '$gray20',
          borderColor: 'transparent',
          color: '$gray40',
        },
        '&:focus': {
          backgroundColor: '$gray80',
        },
        '&:hover:enabled': {
          backgroundColor: '$gray80',
        },
        backgroundColor: '$gray90',
        borderColor: 'transparent',
        color: '$white',
        fontSize: '$14',
        height: '$40',
        padding: '$6 $12',
      },
      refresh: {
        '&:disabled': {
          backgroundColor: '$gray20',
          color: '$gray50',
        },
        '&:hover': {
          backgroundColor: '$gray60',
        },
        backgroundColor: '$gray90',
        borderColor: 'transparent',
        color: '$white',
      },
      secondary: {
        '&:disabled': {
          backgroundColor: '$gray20',
          borderColor: 'transparent',
          color: '$gray40',
        },
        '&:focus': {
          backgroundColor: '$gray10',
          borderColor: '$gray80',
          color: '$gray80',
        },
        '&:hover': {
          backgroundColor: '$gray10',
        },
        backgroundColor: '$white',
        borderColor: '$gray40',
        borderStyle: 'solid',
        borderWidth: '$1',
        color: '$gray80',
      },
      secondarySmall: {
        '&:disabled': {
          backgroundColor: '$gray20',
          color: '$gray50',
        },
        '&:focus': {
          backgroundColor: '$gray30',
          color: '$brandBlack',
        },
        '&:hover': {
          backgroundColor: '$gray20',
        },
        backgroundColor: '$gray10',
        borderColor: 'transparent',
        color: '$brandBlack',
        fontSize: '$12',
        height: '$32',
        padding: '$6 $12',
      },
      white: {
        '&:hover': {
          backgroundColor: '$gray10',
        },
        backgroundColor: '$white',
        borderColor: 'transparent',
        color: '$brandBlack',
      },
      whiteSmall: {
        '&:hover': {
          backgroundColor: '$gray10',
        },
        backgroundColor: '$white',
        borderColor: 'transparent',
        color: '$brandBlack',
      },
    },
  },
});

const GradientBackground = styled('div', {
  '&:after': {
    animation: `${BackgroundKeyframes} 9s ease infinite`,
    background:
      'radial-gradient(109% 898% at 0% 7%, $brandYellow, $brandGreen, $socialReact)',
    borderRadius: 'inherit',
    content: '',
    height: 0,
    left: '50%',
    overflow: 'hidden',
    paddingTop: '100%',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '200%',
    zIndex: -1,
  },
  borderRadius: 'inherit',
  height: '100%',
  left: 0,
  overflow: 'hidden',
  position: 'absolute',
  top: 0,
  width: '100%',
  zIndex: -1,
});

const TextHoverSpan = styled('span', {
  display: 'block',
  transition: `transform ${EASE_OUT_QUAD} 0.3s`,
});

const TextHoverContainer = styled('div', {
  marginRight: '-$8',
  overflow: 'hidden',
  paddingRight: '$8',
  position: 'relative',
  [`${TextHoverSpan}:first-of-type`]: {
    left: 0,
    position: 'absolute',
    transform: 'translateY(-100%) rotate(-13deg)',
    transformOrigin: 'bottom left',
  },
  [`${TextHoverSpan}:last-of-type`]: {
    transformOrigin: 'top right',
  },
  [`${Container}:hover &`]: {
    [`${TextHoverSpan}:first-of-type`]: {
      transform: 'translateY(0) scale(1.02)',
      transformOrigin: 'center center',
    },
    [`${TextHoverSpan}:last-of-type`]: {
      transform: 'translateY(100%) rotate(-13deg)',
    },
  },
});

const spin = keyframes({
  from: {
    transform: 'rotate(0deg)',
  },
  to: {
    transform: 'rotate(360deg)',
  },
});

const LoadingSpinner = styled(LoadingSpinnerIcon, {
  animation: `${spin} 1s linear 0s infinite`,
});

const LoadingSpinnerContainer = styled('div', {
  alignItems: 'center',
  display: 'flex',
  gap: '$4',
});

const WithLoadingSpinner = ({ children }: { readonly children: ReactNode }) => (
  <LoadingSpinnerContainer>
    <LoadingSpinner /> {children}
  </LoadingSpinnerContainer>
);

const PlatformButtonDefaultElement: ElementType = 'button';

type PlatformButtonComponentProps = {
  addBoxShadow?: boolean;
  children: ReactNode;
  css?: CSS;
  disabled?: boolean;
  href?: string;
  icon?: ComponentType<ComponentPropsWithRef<'svg'>>;
  isLoading?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  props?: {};
  variant?:
    | 'dark'
    | 'gradient'
    | 'primary'
    | 'primarySmall'
    | 'secondary'
    | 'secondarySmall'
    | 'white';
};

type PlatformButtonProps<
  C extends ElementType = typeof PlatformButtonDefaultElement,
> = PolymorphicPropsWithoutRef<PlatformButtonComponentProps, C>;

export const PlatformButton: PolymorphicForwardRefExoticComponent<
  PlatformButtonComponentProps,
  typeof PlatformButtonDefaultElement
> = forwardRef(
  <C extends ElementType = typeof PlatformButtonDefaultElement>(
    {
      addBoxShadow = false,
      as,
      children,
      css,
      href,
      icon,
      isLoading,
      onClick,
      variant,
      ...props
    }: PlatformButtonProps<C>,
    ref: Ref<HTMLButtonElement>,
  ) => {
    return (
      <Container
        addBoxShadow={addBoxShadow}
        as={as}
        css={css}
        href={href}
        onClick={onClick}
        ref={ref}
        variant={variant}
        {...props}
      >
        {variant === 'gradient' ? (
          <>
            <TextHoverContainer>
              <TextHoverSpan>
                {isLoading ? (
                  <WithLoadingSpinner>{children}</WithLoadingSpinner>
                ) : (
                  children
                )}
              </TextHoverSpan>
              <TextHoverSpan>
                {isLoading ? (
                  <WithLoadingSpinner>{children}</WithLoadingSpinner>
                ) : (
                  children
                )}
              </TextHoverSpan>
            </TextHoverContainer>
            <GradientBackground />
          </>
        ) : (
          <>
            {icon
              ? createElement(icon, { height: pxToRem(24), width: pxToRem(24) })
              : null}
            <span>
              {isLoading ? (
                <WithLoadingSpinner>{children}</WithLoadingSpinner>
              ) : (
                children
              )}
            </span>
          </>
        )}
      </Container>
    );
  },
);
