import { useMatchMedia } from './useMatchMedia.js';
import { type DefaultProperties } from '@/services/analytics/propertyLibrary.js';
import { minWidthMediaQueries } from '@/stitches/media.js';

export const useDeviceCategory = (): DefaultProperties['device_category'] => {
  const isUltrawide = useMatchMedia(minWidthMediaQueries.bp5);
  const isDesktop = useMatchMedia(minWidthMediaQueries.bp3);
  const isTablet = useMatchMedia(minWidthMediaQueries.bp1);

  if (isUltrawide) return 'ultrawide';
  if (isDesktop) return 'desktop';
  if (isTablet) return 'tablet';

  return 'mobile';
};
