export const LoadingSpinnerIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 32 32"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M30 16c0 7.732-6.268 14-14 14S2 23.732 2 16 8.268 2 16 2"
      stroke="currentColor"
      strokeWidth={4}
    />
  </svg>
);
