import { SEGMENT_CDN_SETTINGS_URL } from './constants.js';
import {
  type AnalyticsContextProps,
  type AnalyticsResourceData,
  type Track,
} from './types.js';
import { fallbackAnalytics, getPosthogMappedFeatureFlags } from './util.js';
import { useCurrentUser } from '@/hooks/useCurrentUser.js';
import { useCurrentUserHasLiveTemplate } from '@/hooks/useCurrentUserHasLiveTemplate.js';
import { useDeviceCategory } from '@/hooks/useDeviceCategory.js';
import { useIsPlaywright } from '@/hooks/useIsPlaywright.js';
import { useMockPreview } from '@/hooks/useMockPreview.js';
import { useSearchParameter } from '@/hooks/useSearchParameter.js';
import { useTemplate } from '@/hooks/useTemplate.js';
import { useTheme } from '@/hooks/useTheme.js';
import { useUserProfile } from '@/hooks/useUserProfile.js';
import { type EventLibrary } from '@/services/analytics/eventLibrary.js';
import { type DefaultProperties } from '@/services/analytics/propertyLibrary.js';
import { type Campaign } from '@/services/analytics/types.js';
import {
  getUtmParametersAsCampaign,
  searchIncludesUtmParameters,
} from '@/services/analytics/util.js';
import { logger } from '@/services/logger.js';
import { getRouteName } from '@/utilities/getRouteName.js';
import { storage } from '@/utilities/storage.js';
import { useMount } from '@contra/react-hooks/useMount';
import { safeStringify } from '@contra/utilities/safeStringify';
import { AnalyticsBrowser, type LegacySettings } from '@segment/analytics-next';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import {
  createContext,
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

const sessionStorage = storage('session');

const { publicRuntimeConfig } = getConfig();
const { segmentCdnUrl, segmentWriteKey } = publicRuntimeConfig;

const log = logger.child({
  namespace: 'AnalyticsContext',
});

export const AnalyticsContext = createContext<AnalyticsContextProps>({
  ...fallbackAnalytics,
  hasLoaded: false,
  resource: null,
  userId: null,
});

export const AnalyticsProvider = ({
  children,
  resource,
  useFallback,
}: {
  readonly children: ReactNode;
  readonly resource: AnalyticsResourceData;
  readonly useFallback?: boolean;
}) => {
  const router = useRouter();
  const currentUser = useCurrentUser();
  const userProfile = useUserProfile();
  const isPlaywright = useIsPlaywright();
  const deviceCategory = useDeviceCategory();
  const { current: currentTheme, currentThemeId } = useTheme();
  const { current: currentTemplate, currentTemplateId } = useTemplate();
  const { isMockPreviewingEnabled, isPreviewingNewTemplate } = useMockPreview();
  const hasLiveTemplate = useCurrentUserHasLiveTemplate();

  const { slug } = router.query;
  const hideTools = useSearchParameter('hideTools', 'boolean');

  const [cdnSettingsFetchHasCompleted, setCdnSettingsFetchHasCompleted] =
    useState(false);
  const [cdnSettings, setCdnSettings] = useState<
    (LegacySettings & Record<string, unknown>) | undefined
  >(undefined);
  const [analytics, setAnalytics] = useState(fallbackAnalytics);
  const [campaign, setCampaign] = useState<Campaign | null>(null);

  const userId = analytics.instance?.user().id() ?? null;

  const fetchCdnSettings = useCallback(async () => {
    try {
      const response = await fetch(SEGMENT_CDN_SETTINGS_URL);
      const settings = await response.json();
      setCdnSettingsFetchHasCompleted(true);
      setCdnSettings(settings);
    } catch {
      log.error('Failed to fetch CDN settings');
      setCdnSettingsFetchHasCompleted(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useMount(() => {
    void fetchCdnSettings();
  });

  useEffect(() => {
    const serializedCampaign = sessionStorage.getItem
      ? sessionStorage.getItem('CONTRA_UTM_CAMPAIGN')
      : undefined;

    if (serializedCampaign) {
      setCampaign(JSON.parse(serializedCampaign));
      return;
    }

    if (searchIncludesUtmParameters(router.query)) {
      const nextCampaign = getUtmParametersAsCampaign(router.query);

      if (sessionStorage.setItem) {
        sessionStorage.setItem(
          'CONTRA_UTM_CAMPAIGN',
          safeStringify(nextCampaign),
        );
      }

      setCampaign(nextCampaign);
    }
  }, [router.query]);

  const segmentContext = useMemo(() => {
    return {
      // "campaign" is included by default by analytics-next
      // https://segment.com/docs/connections/spec/common/#structure
      // However, if you analyze their implementation, it does not persist campaign between page loads.
      // https://github.com/segmentio/analytics-next/blob/8804fa820f60b9b98684ed8df7d23ed5253d510e/src/plugins/segmentio/normalize.ts#L156
      // This is why we are providing our own context with campaign that is persisted in session storage.
      campaign: campaign ?? undefined,
    };
  }, [campaign]);

  const mappedFeatureFlags = useMemo(
    () => getPosthogMappedFeatureFlags(currentUser?.featureFlags),
    [currentUser?.featureFlags],
  );

  const defaultProperties = useMemo<DefaultProperties>(
    () => ({
      ...mappedFeatureFlags,
      current_project_id:
        resource?.type === 'project' ? resource.id : undefined,
      current_project_slug:
        resource?.type === 'project' && typeof slug === 'string'
          ? slug
          : undefined,
      current_service_id:
        resource?.type === 'service' ? resource.id : undefined,
      current_service_slug:
        resource?.type === 'service' && typeof slug === 'string'
          ? slug
          : undefined,
      current_user_type:
        currentUser?.userAccount?.onboardingType.toLowerCase() ?? undefined,
      device_category: deviceCategory,
      global_template_id: currentTemplateId ?? null,
      global_template_name: currentTemplate ?? null,
      global_template_theme_id: currentThemeId ?? null,
      global_template_theme_name: currentTheme ?? null,
      has_active_subscription:
        currentUser?.userAccount?.profile.hasActivePortfolioSubscription,
      has_custom_domain: Boolean(
        currentUser?.userAccount?.customDomains?.count,
      ),
      has_live_template: hasLiveTemplate,
      is_authenticated: Boolean(currentUser?.userAccount),
      is_owner: Boolean(userProfile?.visitorCanEdit),
      is_preview_mode: hideTools,
      is_previewing_new_template: isPreviewingNewTemplate,
      is_profile_completed: currentUser?.isProfileCompleted ?? false,
      is_receiving_inquiries: Boolean(
        currentUser?.userAccount?.profile.generalInquiryCtaIsVisible,
      ),
      is_viewing_mock_content: isMockPreviewingEnabled,
      route_name: getRouteName(router.pathname),
      user_profile_general_inquiry_cta_is_visible: Boolean(
        userProfile?.generalInquiryCtaIsVisible,
      ),
      user_profile_id: userProfile?.analyticsId ?? null,
    }),
    [
      currentTemplate,
      currentTemplateId,
      currentTheme,
      currentThemeId,
      mappedFeatureFlags,
      currentUser?.isProfileCompleted,
      currentUser?.userAccount,
      deviceCategory,
      hasLiveTemplate,
      hideTools,
      isMockPreviewingEnabled,
      isPreviewingNewTemplate,
      resource,
      router.pathname,
      slug,
      userProfile?.analyticsId,
      userProfile?.generalInquiryCtaIsVisible,
      userProfile?.visitorCanEdit,
    ],
  );

  const track = useCallback<Track>(
    <T extends keyof EventLibrary>(
      eventName: T,
      properties?: EventLibrary[T],
    ) => {
      const nextProperties = {
        ...defaultProperties,
        // Order matters here, sometimes we need
        // to overwrite global properties
        ...properties,
      };

      log.debug(
        {
          context: segmentContext,
          eventName,
          properties: nextProperties,
        },
        'track %s',
        eventName,
      );

      void analytics.track(eventName, nextProperties, {
        context: segmentContext,
      });
    },
    [analytics, defaultProperties, segmentContext],
  );

  const alias = useCallback(
    (distinctId: string, fromDistinctId?: string) => {
      log.debug({ distinctId, fromDistinctId }, 'alias');

      void analytics.alias(distinctId, fromDistinctId);
    },
    [analytics],
  );

  const identify = useCallback(
    (distinctId: string, traits?: object) => {
      const properties = {
        ...traits,
      };

      log.debug(
        { context: segmentContext, distinctId, properties },
        'identify',
      );

      void analytics.identify(distinctId, properties, {
        context: segmentContext,
      });
    },
    [analytics, segmentContext],
  );

  const reset = useCallback(() => {
    log.debug('reset');

    void analytics.reset();
  }, [analytics]);

  useEffect(() => {
    let analyticsBootstrap: AnalyticsBrowser | null = null;
    if (
      !isPlaywright &&
      segmentCdnUrl &&
      segmentWriteKey &&
      cdnSettingsFetchHasCompleted &&
      currentUser?.areAnalyticsEnabled &&
      !useFallback &&
      !analytics.ready
    ) {
      analyticsBootstrap = AnalyticsBrowser.load(
        {
          cdnSettings,
          cdnURL: segmentCdnUrl,
          writeKey: segmentWriteKey,
        },
        {
          integrations: {
            'Segment.io': {
              deliveryStrategy: {
                config: {
                  size: 50,
                  timeout: 5_000,
                },
                strategy: 'batching',
              },
            },
          },
        },
      );

      void analyticsBootstrap.ready(async () => {
        log.info('Loaded Segment');
        if (currentUser?.analyticsUserId) {
          log.info(
            { analyticsUserId: currentUser?.analyticsUserId },
            'Segment.identify non-anonymous user',
          );
          void analyticsBootstrap?.identify(currentUser?.analyticsUserId);
        } else if (
          analyticsBootstrap?.instance?.user().id() !== currentUser?.sessionId
        ) {
          log.info(
            { sessionId: currentUser?.sessionId },
            'Segment.identify first-time anonymous user',
          );
          void analyticsBootstrap?.identify(currentUser?.sessionId);
        }

        setAnalytics(analyticsBootstrap ?? fallbackAnalytics);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cdnSettings, currentUser]);

  const reactContext = useMemo(() => {
    return {
      alias,
      hasLoaded: Boolean(analytics.instance),
      identify,
      reset,
      resource,
      track,
      userId,
    };
  }, [alias, analytics.instance, identify, reset, resource, track, userId]);

  const segmentUserId = analytics.instance?.user().id() ?? null;
  const analyticsUserId = currentUser?.userAccount?.analyticsUserId;

  useEffect(() => {
    // Only trigger identify when analytics library
    // does not already have analytics user id stored
    // for authenticated user
    // FYI, analytics JS stores this data in both local storage and
    // cookies, so if you are not seeing any identifies, this won't run
    // until you've cleared both.
    if (
      !analytics.instance ||
      !analyticsUserId ||
      (segmentUserId && analyticsUserId && analyticsUserId === segmentUserId)
    ) {
      return;
    }

    identify(analyticsUserId, {});
  }, [
    alias,
    identify,
    analyticsUserId,
    segmentUserId,
    analytics.instance,
    currentUser?.userAccount,
  ]);

  return (
    <AnalyticsContext.Provider value={reactContext}>
      {children}
    </AnalyticsContext.Provider>
  );
};
