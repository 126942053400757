import { logger } from '@/services/logger.js';
import { type FeatureFlags } from '@/utilities/getDefaultFeatureFlags.js';
import { type AnalyticsBrowser } from '@segment/analytics-next';

const log = logger.child({
  namespace: 'AnalyticsContext:util',
});

export const fallbackAnalytics = {
  alias: () => {
    log.warn('"alias" used without Analytics');
  },
  identify: () => {
    log.warn('"identify" used without Analytics');
  },
  page: () => {
    log.warn('"page" used without Analytics');
  },
  reset: () => {
    log.warn('"reset" used without Analytics');
  },
  track: () => {
    log.warn('"track" used without Analytics');
  },
} as unknown as AnalyticsBrowser;

/**
 * We're updating the featureFlags to be keyed in the format $feature/<flagName>.
 * This is the format that PostHog expects to extra feature flags for things like
 * Experiments.
 *
 * See: https://posthog.com/tutorials/experiments#step-2-sending-the-right-events
 */
export const getPosthogMappedFeatureFlags = (
  featureFlags?: FeatureFlags | null,
) => {
  if (!featureFlags) return {};
  const featureFlagEntries = Object.entries(featureFlags);
  const updatedFeatureFlagEntries = featureFlagEntries.map(
    ([flagKey, value]) => [`$feature/${flagKey}`, value],
  );
  return Object.fromEntries(updatedFeatureFlagEntries);
};
