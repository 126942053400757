/* eslint-disable require-unicode-regexp */
export const prettifyPathname = (path: string) => {
  return (
    path
      .replaceAll(/^\/+/g, '')
      .replaceAll(/\[+/g, '')
      // eslint-disable-next-line regexp/strict
      .replaceAll(/]+/g, '')
      .replaceAll(':', '')
      .replaceAll(/[/-]/g, '_')
  );
};
