import { useCurrentUser } from './useCurrentUser.js';
import { useMemo } from 'react';

export const useCurrentUserHasLiveTemplate = () => {
  const currentUser = useCurrentUser();
  return useMemo(
    () =>
      currentUser?.userAccount?.profile.independentPortfolioTemplateTheme !==
      null,
    [currentUser],
  );
};
